import cn from 'classnames'

import { DrawerProps } from '@components/Drawer/interface'

export function Drawer({
  children,
  isOpen,
  currentDrawer,
  closeDrawer,
}: DrawerProps) {
  return (
    <>
      <div
        className={cn(
          'fixed w-full z-[100] bg-secondary-2/30 inset-0 overflow-hidden',
          {
            'translate-x-full': !isOpen,
            'mobile:translate-x-full': currentDrawer === 'chatDrawer',
          }
        )}
        onClick={closeDrawer}
      />
      <div
        className={cn(
          'fixed z-[100] bg-white dark:bg-dark-8 w-[700px] right-0 transition-all duration-500 max-h-screen h-full desktop-tablet:top-0 mobile:bottom-0 mobile:w-full mobile:scrollbar-none drawer',
          {
            'mobile:translate-y-[130px] overflow-y-auto scrollbar-none':
              currentDrawer !== 'chatDrawer' &&
              currentDrawer !== 'categoryMenu' &&
              isOpen,
            'mobile:translate-y-0 overflow-hidden':
              currentDrawer === 'categoryMenu' && isOpen,
            'max-w-[540px] mobile:mobile:translate-y-[60px]':
              currentDrawer === 'chatDrawer' && isOpen,
            'desktop-tablet:translate-x-full mobile:translate-y-full': !isOpen,
          }
        )}
        id='drawer-id'
      >
        {children}
      </div>
    </>
  )
}
