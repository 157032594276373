import { ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { MoonIcon } from '@components/icons/MoonIcon'
import { LoadingModalProps } from './interface'

export function LoadingModal({ isOpen }: LoadingModalProps) {
  const [component, setComponent] = useState<ReactPortal | null>(null)

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''

    setComponent(
      isOpen
        ? createPortal(
            <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-[100]'>
              <div className='w-[353px] h-[178px] bg-loading dark:bg-loading-dark mobile:bg-[length:250px_126px] bg-no-repeat bg-center flex items-center justify-center text-white'>
                <MoonIcon
                  className='mr-[10px] animate-spin-slow'
                  width='40'
                  height='40'
                />
                <span className='text-[24px] font-sarabun font-medium'>
                  Loading...
                </span>
              </div>
            </div>,
            document.body
          )
        : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return component
}
