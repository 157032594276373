import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { formatDateDomini } from '@lib/utils'
import { WriterEnum } from '@interfaces/WriterEnum'
import { API_URL } from '@configs/config'
import { useAlert } from '@hooks/useAlert'
import { PolicyDocument } from './PolicyDocument'

export interface WriterTermsOfServiceModalProps {
  title: string
  content: string
  submittedDate: string
  showApproveAction: boolean
  hideCloseButton?: boolean
  onApprove?: () => void
}

export function WriterTermsOfServiceModal({
  title,
  content,
  submittedDate,
  showApproveAction,
  hideCloseButton = false,
  onApprove,
}: WriterTermsOfServiceModalProps) {
  const { user } = useAuthentication()
  const [canApprove, setCanApprove] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const termsOfServiceModal = useModal({ modal: 'writerTermsOfService' })
  const alert = useAlert()

  function handleApprove() {
    if (onApprove) {
      onApprove()
    }
  }

  useEffect(() => {
    function handleClick(event: any) {
      event.target.classList.toggle('is-active')
      const contentElem = event.target.nextElementSibling
      if (event.target.classList.contains('is-active')) {
        contentElem.style.display = 'block'
      } else {
        contentElem.style.display = 'none'
      }
    }
    const elems = document.querySelectorAll('div.consent-collapsible')
    elems.forEach(elem => {
      elem.addEventListener('click', handleClick)
    })

    return () => {
      if (elems) {
        elems.forEach(elem => {
          elem.removeEventListener('click', handleClick)
        })
      }
    }
  }, [title, content])

  async function generatePDF() {
    const apiUrl = API_URL.replace('/graphql', '')
    const response = await fetch(
      `${apiUrl}/pdpa/download-writer-consent?userId=${user.id}`,
      {
        method: 'GET',
      }
    )

    if (response.status === 200) {
      const blob = await response.blob()
      const fileUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = fileUrl
      a.download = `ข้อตกลงการให้บริการระบบวรรณกรรมอิเล็กทรอนิกส์.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(fileUrl)
    } else {
      alert.error('เกิดข้อผิดพลาดไม่สามารถดาวน์โหลดเอกสารได้')
    }
  }

  return (
    <div>
      <div className='w-[800px] tablet:w-[700px] mobile:w-[348px] py-[30px] mobile:py-[20px]'>
        <h2 className='font-mitr text-[24px] text-secondary dark:text-white-2 font-medium px-[30px] mobile:px-[20px]'>
          {title}
        </h2>
        {submittedDate && (
          <p className='font-sarabun text-[12px] text-secondary-1 dark:text-dark-10 font-light my-[10px] px-[30px] mobile:px-[20px]'>
            Submitted Date : {formatDateDomini(submittedDate)}
          </p>
        )}
        <div
          className='font-sarabun h-[380px] text-[14px] text-secondary dark:text-white-2 font-light mt-[10px] overflow-y-auto pl-[30px] pr-[20px] mobile:pl-[20px] mobile:pr-[10px]'
          onScroll={(e: any) => {
            const scrollHeight = e.target.scrollHeight - 10
            const scrollTop = e.target.scrollTop + e.target.offsetHeight

            if (scrollTop >= scrollHeight) {
              setCanApprove(true)
            }
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {showApproveAction ? (
          <div className='flex justify-center font-mitr text-[14px] mt-[20px] px-[30px] mobile:px-[20px] space-x-[20px]'>
            <button
              className={cn(
                'p-[10px_20px] text-secondary-1 dark:text-dark-10 border border-secondary-1 dark:border-dark-10 rounded-[20px]',
                {
                  hidden: hideCloseButton,
                }
              )}
              type='button'
              onClick={() => termsOfServiceModal.hide()}
            >
              ยกเลิก
            </button>
            <button
              className='p-[10px_20px] rounded-[20px] bg-primary dark:bg-purple text-white dark:text-white-2 disabled:opacity-30'
              type='button'
              disabled={!canApprove}
              onClick={handleApprove}
            >
              ยอมรับ
            </button>
          </div>
        ) : (
          <div className='flex items-center justify-center mt-[10px] px-[30px] mobile:px-[20px]'>
            <button
              className='text-[12px] text-center hover:text-blue dark:text-white-2'
              type='button'
              onClick={generatePDF}
            >
              ดาวน์โหลดเอกสาร
            </button>
          </div>
        )}
      </div>
      <PolicyDocument
        ref={ref}
        title={title}
        content={content}
        submittedDate={submittedDate}
        fullName={
          user.writerRequest?.type === WriterEnum.INDIVIDUAL
            ? user.writerRequest?.fullName
            : user.writerRequest?.companyName
        }
        address={
          user.writerRequest?.type === WriterEnum.INDIVIDUAL
            ? `${user.writerRequest?.address} ${user.writerRequest?.subDistrict} ${user.writerRequest?.district} ${user.writerRequest?.province} ${user.writerRequest?.postcode}`
            : `${user.writerRequest?.sendAddress} ${user.writerRequest?.sendSubDistrict} ${user.writerRequest?.sendDistrict} ${user.writerRequest?.sendProvince} ${user.writerRequest?.sendPostcode}`
        }
        phoneNumber={
          user.writerRequest?.type === WriterEnum.INDIVIDUAL
            ? user.writerRequest?.phoneNumber
            : user.writerRequest?.coordinatorPhoneNumber
        }
        email={user.email}
        firstLoginProvider={user.firstLoginProvider}
      />
    </div>
  )
}
