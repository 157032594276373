import React, { useState, useContext, useEffect, useLayoutEffect } from 'react'
import cn from 'classnames'
import Router from 'next/router'
import { FontFamilyEnum } from '@interfaces/FontFamilyEnum'
import {
  ThemeProviderPropsType,
  ThemeContextType,
  ThemeEnum,
} from './interface'

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: ThemeEnum.LIGHT,
  fontSize: 18,
  lineHeight: 1.5,
  fontFamily: FontFamilyEnum.JS_JINDARA,
  isLightTheme: false,
  isSepiaTheme: false,
  isDarkTheme: false,
  isNightTheme: false,
  isNightOrDarkTheme: false,
  isLightOrSepiaTheme: false,
  updateTheme: (type: ThemeEnum, isSetLocal = false) => {},
  updateFontSize: (value: number) => {},
  updateLineHeight: (value: number) => {},
  updateFontFamily: (value: FontFamilyEnum) => {},
  setInitialData: () => {},
  setThemeFromLocalStorage: () => {},
})

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : () => {}

export function ThemeProvider({ children }: ThemeProviderPropsType) {
  const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.LIGHT)
  const [fontSize, setFontSize] = useState<number>(18)
  const [lineHeight, setLineHeight] = useState<number>(1.5)
  const [fontFamily, setFontFamily] = useState<FontFamilyEnum>(
    FontFamilyEnum.JS_JINDARA
  )

  useIsomorphicLayoutEffect(() => {
    setThemeFromLocalStorage()
  }, [theme])

  function updateTheme(type: ThemeEnum) {
    if (type === ThemeEnum.DARK || type === ThemeEnum.NIGHT) {
      document.body.style.background = '#000000'
    } else {
      document.body.style.background = '#ffffff'
    }
    localStorage.setItem('colorTheme', type)
    setTheme(type)
  }

  function updateFontSize(value: number) {
    localStorage.setItem('fontSize', String(value))
    setFontSize(value)
  }

  function updateLineHeight(value: number) {
    localStorage.setItem('lineHeight', String(value))
    setLineHeight(value)
  }

  function updateFontFamily(value: FontFamilyEnum) {
    localStorage.setItem('fontFamily', value)
    setFontFamily(value)
  }

  function setInitialData() {
    setTheme(ThemeEnum.LIGHT)
  }

  function setThemeFromLocalStorage() {
    const defaultColorTheme = [
      ThemeEnum.DARK,
      ThemeEnum.LIGHT,
      ThemeEnum.NIGHT,
      ThemeEnum.SEPIA,
    ].includes(localStorage.getItem('colorTheme') as ThemeEnum)
      ? (localStorage.getItem('colorTheme') as ThemeEnum)
      : ThemeEnum.LIGHT
    const defaultFont = ['14', '16', '18', '20', '22', '24'].includes(
      localStorage.getItem('fontSize') || ''
    )
      ? Number(localStorage.getItem('fontSize'))
      : 18

    const defaultLineHieght = ['1.25', '1.5', '2', '2.5', '3'].includes(
      localStorage.getItem('lineHeight') || ''
    )
      ? Number(localStorage.getItem('lineHeight'))
      : 1.5

    const defaultFontFamily = [
      FontFamilyEnum.BAI_JAMJUREE,
      FontFamilyEnum.JS_JINDARA,
      FontFamilyEnum.NOTO_SERIF,
      FontFamilyEnum.SARABUN,
      FontFamilyEnum.TRIRONG,
    ].includes((localStorage.getItem('fontFamily') as FontFamilyEnum) || '')
      ? (localStorage.getItem('fontFamily') as FontFamilyEnum)
      : FontFamilyEnum.SARABUN

    if (
      defaultColorTheme === ThemeEnum.DARK ||
      defaultColorTheme === ThemeEnum.NIGHT
    ) {
      document.body.style.background = '#000000'
    } else {
      document.body.style.background = '#ffffff'
    }

    setTheme(defaultColorTheme)
    setFontSize(defaultFont)
    setLineHeight(defaultLineHieght)
    setFontFamily(defaultFontFamily)
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        fontSize,
        lineHeight,
        fontFamily,
        isNightOrDarkTheme: [ThemeEnum.NIGHT, ThemeEnum.DARK].includes(theme),
        isLightOrSepiaTheme: [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme),
        isLightTheme: ThemeEnum.LIGHT === theme,
        isSepiaTheme: ThemeEnum.SEPIA === theme,
        isDarkTheme:
          ThemeEnum.DARK === theme &&
          Router.pathname === '/story/[id]/chapter/[chapterId]',
        isNightTheme:
          ThemeEnum.NIGHT === theme ||
          (ThemeEnum.DARK === theme &&
            Router.pathname !== '/story/[id]/chapter/[chapterId]'),
        updateTheme,
        updateFontSize,
        updateLineHeight,
        setInitialData,
        setThemeFromLocalStorage,
        updateFontFamily,
      }}
    >
      <div
        className={cn({
          dark: theme === ThemeEnum.NIGHT || theme === ThemeEnum.DARK,
        })}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}
