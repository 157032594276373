import React from 'react'
import cx from 'classnames'

import { CrossIcon } from '@components/icons/CrossIcon'
import { ModalProps } from './interface'
import { withModal } from './withModal'

function Modal({
  children,
  disabledClose,
  disableBackground,
  onClose,
}: ModalProps) {
  return (
    <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-[100]'>
      <div
        className={cx(
          'relative  flex flex-col w-auto h-auto max-w-full max-h-[90%] overflow-auto rounded-[10px] scrollbar-none',
          { 'bg-white dark:bg-dark-8': !disableBackground }
        )}
      >
        <div className='absolute right-[10px] top-[10px] z-[1] flex justify-end min-h-[20px]'>
          {!disabledClose && (
            <button type='button' onClick={onClose} data-test='close-button'>
              <CrossIcon className='text-secondary dark:text-white-2' />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default withModal(Modal)
